$(function () {
  $('#close_flash').on('click', () => {
      $('.alert').hide();
  });
});

$(function () {
  $('#close_flash').on('click', () => {
      $('.notice').hide();
  });
});